<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Admin Service Settings</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="adminServices" :lazy="true" :paginator="false" :loading="loading"
                                class="p-datatable-users" data-key="component_id" :rowHover="true">
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column header="Sr." headerStyle="width: 5%">
                                    <template #body="{ index }">
                                        {{ ++index }}
                                    </template>
                                </Column>
                                <Column header="Service Name" field="components_name" headerStyle="width: 75%"></Column>
                                <Column header="Master Service Status" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <InputSwitch v-model="data.service_status_by_admin"
                                            @change="serviceStatusChanges(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../../service/ApiService';
import { useRoute } from 'vue-router';
import router from '@/router';

export default {
    data() {
        return {
            adminServices: [],
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            switchuncheck: false,
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,
            localAdminName: '',
        };
    },

    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.getAdminServiceSettings({ client_id: this.routeParam });
    },
    methods: {
        serviceStatusChanges(data) {
            let fields = {};
            fields["client_id"] = this.routeParam;
            if (data.service_status_by_admin == 0 || data.service_status_by_admin == false) {
                fields["service_status_by_admin"] = 0;
            }
            if (data.service_status_by_admin == 1 || data.service_status_by_admin == true) {
                fields["service_status_by_admin"] = 1;
            }
            fields["component_id"] = data.component_id;
            fields["components_name"] = data.components_name;
            fields["serviceSettingId"] = data.serviceSettingId;
            this.ApiService.addClientServiceSettings(fields).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.getAdminServiceSettings({ client_id: this.routeParam });
                }
                else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAdminServiceSettings({ page_no: event.page });
        },

        async getAdminServiceSettings(payload) {
            try {
                const result = await this.ApiService.getClientServiceSettings(payload);
                this.adminServices = [];
                let adminServices = result.data;
                let object = Object.values(adminServices);
                               
                for (let i = 0; i < object.length; i++) {
                    if (object[i]['service_status_by_admin'] != null) {
                        this.adminServices.push({
                            'component_id': object[i]['component_id'],
                            'components_name': object[i]['components_name'],
                            'serviceSettingId': object[i]['serviceSettingId'],
                            'client_id': object[i]['client_id'],
                            'service_status_by_admin': object[i]['service_status_by_admin'] == 1 ? true : false,
                        });
                    }
                }
                if (!result.success) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            } catch (error) {
                return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            }
        },

        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },

            });
        },
        goToPrevious() {
            router.push({
                name: "admindocument",
                params: { adminSubId: this.routeParam },

            });
        },
        goToNext() {
            router.push({
                name: "add-debit",
                params: { adminSubId: this.routeParam },
            });
        },
    }

};
</script>

<style scoped></style>
